<template>
  <section
    v-if="showEndorsements"
    class="candidate-profile-endorsements"
    data-test="endorsements"
  >
    <h3
      class="candidate-profile-endorsements__title"
      data-test="profile-endorsements-title"
    >
      {{ $t(`${langPath}.title`) }}
    </h3>

    <div
      v-for="endorsement in candidate.endorsements"
      :key="endorsement.id"
      class="candidate-profile-endorsements__item"
    >
      <blockquote class="candidate-profile-endorsements__item-text">
        "{{ endorsement.text }}"
      </blockquote>
      <p class="candidate-profile-endorsements__item-author">
        {{ endorsement.referrerName }} -
        {{ endorsement.endorserPosition }} {{ endorsement.companyName }}
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CandidateProfileEndorsements',

  props: {
    candidate: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      langPath: __langpath
    }
  },

  computed: {
    showEndorsements () {
      return this.candidate.endorsements?.length
    }
  }
}
</script>

<style lang="scss" scoped>
.candidate-profile-endorsements {
  @extend %candidate-profile-top-border;

  &__title {
    @extend %subtitle1;
  }

  &__item {
    @include margin(top, 4);

    border-left: 5px solid var(--b-neutral-light-04);
    padding-left: 4*$base;

    &-text {
      @extend %body-text2;
    }

    &-author {
      @include margin(top, 4);

      @extend %subtitle2;

      text-align: right;
    }
  }
}
</style>
