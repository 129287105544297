<template>
  <section v-if="hasJobs" class="candidate-profile-jobs">
    <h3 class="candidate-profile-jobs__title" data-test="profile-jobs-title">
      {{ $t(`${langPath}.title`) }}
    </h3>

    <candidate-profile-job-experience
      v-for="job in orderedJobs"
      :key="job.id"
      :highlighted-skill-ids="highlightedSkillIds"
      :job="job"
    />
  </section>
</template>

<script>
import CandidateProfileJobExperience from './CandidateProfileJobs/CandidateProfileJobExperience'
import orderBy from 'lodash/orderBy'

export default {
  name: 'CandidateProfileJobs',

  components: {
    CandidateProfileJobExperience
  },

  props: {
    highlightedSkillIds: {
      type: Array,
      default: () => []
    },

    candidate: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      langPath: __langpath
    }
  },

  computed: {
    orderedJobs () {
      return orderBy(this.candidate.jobs, 'startDate', 'desc')
    },
    hasJobs () {
      return this.candidate.jobs && this.candidate.jobs.length > 0
    }
  }
}
</script>

<style lang="scss" scoped>
.candidate-profile-jobs {
  @extend %candidate-profile-top-border;

  &__title {
    @extend %subtitle1;
  }
}
</style>
