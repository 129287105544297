<template>
  <div
    v-if="hasSpokenLanguages"
    class="candidate-profile-header-languages"
  >
    <dl class="candidate-profile-header-languages__list">
      <div
        v-for="spokenLanguage in spokenLanguages"
        :key="spokenLanguage.id"
        class="candidate-profile-header-languages__item"
        data-test="language"
      >
        <dt class="candidate-profile-header-languages__label">
          {{ spokenLanguage.language }}
        </dt>

        <dd class="candidate-profile-header-languages__proficiency">
          {{ spokenLanguage.level }}
        </dd>
      </div>
    </dl>
  </div>
</template>

<script>
export default {
  name: 'CandidateProfileHeaderLanguages',

  props: {
    candidate: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      langPath: __langpath
    }
  },

  computed: {
    spokenLanguages () {
      return this.candidate.spokenLanguages.map((spokenLanguage) => ({
        id: spokenLanguage.id,
        language: this.$t(`${this.langPath}.languages.${spokenLanguage.language}`),
        level: this.$t(`${this.langPath}.levels.${spokenLanguage.level}`)
      }))
    },

    hasSpokenLanguages () {
      return this.candidate.spokenLanguages?.length > 0
    }
  }
}
</script>

<style lang="scss" scoped>
.candidate-profile-header-languages {
  @include margin(top, 6);

  &__list {
    display: flex;
    flex-flow: row wrap;
  }

  &__item {
    flex-grow: 1;

    @include breakpoint(md) {
      margin-bottom: 10px;
      width: 50%;
    }
  }

  &__label {
    @extend %caption;

    color: var(--tx-neutral-light-01);
  }

  &__proficiency {
    @extend %body-text2;
  }
}
</style>
