<template>
  <section v-if="videoUrl" class="candidate-profile-video">
    <h3
      class="candidate-profile-video__title"
      data-test="candidate-profile-video-title"
    >
      {{ $t(`${langPath}.title`) }}
    </h3>

    <iframe
      v-if="isFromYoutube"
      class="candidate-profile-video__iframe"
      :src="videoUrl"
      frameborder="0"
      showinfo="0"
      autohide="1"
      controles="0"
      allowfullscreen
    />

    <video
      v-else
      :src="videoUrl"
      class="candidate-profile-video__iframe"
    />
  </section>
</template>

<script>
export default {
  name: 'CandidateProfileVideo',

  props: {
    candidate: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      langPath: __langpath
    }
  },

  computed: {
    videoUrl () {
      if (!this.candidate.videoUrl) return

      if (this.isFromYoutube) {
        return this.handleYoutubeLink(this.candidate.videoUrl)
      } else {
        return this.candidate.videoUrl
      }
    },

    isFromYoutube () {
      return (/youtube/.test(this.candidate.videoUrl) ||
        /youtu\.be/.test(this.candidate.videoUrl))
    }
  },

  methods: {
    handleYoutubeLink (url) {
      if (/embed/.test(url)) return url

      const youtubeRegex = /(?:v=|video_id=|youtu.be\/)([^&\n\s]+)/

      const urlMatch = url.match(youtubeRegex)
      if (!urlMatch) return

      const videoId = urlMatch[1]

      return `https://www.youtube.com/embed/${videoId}`
    }
  }
}
</script>

<style lang="scss" scoped>
.candidate-profile-video {
  @extend %candidate-profile-top-border;

  &__title {
    @extend %subtitle1;
  }

  &__iframe {
    @include margin(top, 4);

    height: 100*$base;
    width: 100%;
  }
}
</style>
