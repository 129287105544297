<template>
  <section
    v-if="hasInfo"
    class="candidate-profile-about"
    data-test="candidate-profile-about"
  >
    <section
      v-if="candidate.hasBasicInfo"
      class="candidate-profile-about__section"
      data-test="summary-section"
    >
      <h4 class="candidate-profile-about__title" data-test="about-title">
        {{ $t(`${langPath}.about`) }}
        {{ candidate.name }}
      </h4>

      <p
        class="candidate-profile-about__description"
        data-test="about-description"
      >
        {{ candidate.workSummary }}
      </p>
    </section>

    <section
      v-if="hasPrincipalSkills"
      class="candidate-profile-about__section"
      data-test="main-skills"
    >
      <h4 class="candidate-profile-about__title" data-test="main-skills-title">
        {{ $t(`${langPath}.mainSkills`) }}
      </h4>

      <ul class="candidate-profile-about__main-skills">
        <li
          v-for="skill in candidate.principalSkills"
          :key="skill.value"
          class="candidate-profile-about__main-skill"
        >
          <ev-badge
            :title="skill.label"
            :variant="skillBadgeVariant(skill.value)"
            data-test="main-skill-badge"
          />

          <span
            v-if="skill.experienceTime"
            class="candidate-profile-about__main-skill-period"
            data-test="skill-experience-time"
          >
            {{ experienceTimeTranslation(skill.experienceTime) }}
          </span>
        </li>
      </ul>
    </section>

    <section
      v-if="hasCertificates"
      data-test="profile-certificates"
      class="candidate-profile-about__section"
    >
      <h4
        class="candidate-profile-about__title"
        data-test="profile-cerfifications-title"
      >
        {{ $t(`${langPath}.certifications`) }}
      </h4>

      <ul class="candidate-profile-about__certificates-list">
        <li
          v-for="certification in certificates"
          :key="certification.id"
          class="certification"
        >
          <button
            class="candidate-profile-about__certificates-link"
            :data-test="'open-certification-modal-' + certification.id"
            @click="setShowModal(certification.id)"
          >
            {{ certification.name }}
          </button>
        </li>
      </ul>

      <app-modal v-if="certificateShownInModal" scroll @close="closeModal">
        <template #header>
          <h2 class="candidate-profile-about__certificates-modal-header">
            {{ certificateShownInModal.name }}
          </h2>
        </template>

        <section class="candidate-profile-about__certificates-modal-content">
          <p class="candidate-profile-about__certificates-modal-description">
            {{ certificateShownInModal.description }}
          </p>

          <div class="candidate-profile-about__certificates-modal-additional">
            <h3
              class="candidate-profile-about__certificates-modal-title"
              data-test="candidate-profile-about__certificates-modal-additional-info"
            >
              {{ $t(`${langPath}.certificateAdditionalInfo`) }}
            </h3>

            <p
              v-for="infos in certificateShownInModal.additionalInformations"
              :key="infos"
              class="candidate-profile-about__certificates-modal-description"
            >
              {{ infos }}
            </p>
          </div>

          <div
            v-if="certificateShownInModal.validateLink"
            class="candidate-profile-about__certificates-modal-link"
          >
            <h3
              class="candidate-profile-about__certificates-modal-title"
              data-test="certification-modal-validation-title"
            >
              {{ $t(`${langPath}.certificateValidationTitle`) }}
            </h3>
            <span
              class="certification-modal__description"
              data-test="certification-modal-validation-text"
            >
              <a
                :href="certificateShownInModal.validateLink"
                target="_blank"
                class="certification-modal__item"
              >
                {{ $t(`${langPath}.certificateClickHere`) }}
              </a>
              {{ $t(`${langPath}.certificateValidationText`) }}
            </span>
          </div>
        </section>
      </app-modal>
    </section>

    <section v-if="hasSkills" class="candidate-profile-about__section">
      <h4 class="candidate-profile-about__title" data-test="skills-title">
        {{ $t(`${langPath}.generalSkills`) }}
      </h4>

      <app-view-more>
        <div class="candidate-profile-about__skills" data-test="skills-list">
          <ev-badge
            v-for="skill in candidate.skills"
            :key="skill.id"
            :title="skill.label"
            :variant="skillBadgeVariant(skill.value)"
            class="skill"
            data-test="skill"
          />
        </div>
      </app-view-more>
    </section>
  </section>
</template>

<script>
import EvBadge from '@revelotech/everestV2/EvBadge'

import { toCamelCase } from '@/helpers/case-style'

export default {
  name: 'CandidateProfileAbout',

  components: {
    EvBadge
  },

  props: {
    candidate: {
      type: Object,
      required: true
    },

    highlightedSkillIds: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      langPath: __langpath,
      modalShownCertificateId: undefined
    }
  },

  computed: {
    hasCertificates () {
      return this.candidate.candidateCertificates?.length
    },

    hasPrincipalSkills () {
      return this.candidate.principalSkills?.length
    },

    hasSkills () {
      return this.candidate.skills?.length
    },

    hasInfo () {
      return this.candidate.hasBasicInfo ||
        this.hasSkills ||
        this.hasPrincipalSkills ||
        this.hasCertificates
    },

    certificates () {
      return this.candidate.candidateCertificates.map((certificate) => ({
        ...certificate,
        showModal: this.showModal === certificate.id
      }))
    },

    certificateShownInModal () {
      return this.certificates.find(
        ({ id }) => id === this.modalShownCertificateId
      )
    }
  },

  methods: {
    skillBadgeVariant (skillId) {
      const highlighted = this.highlightedSkillIds.find(
        (highlightedSkillId) => String(highlightedSkillId) === String(skillId)
      )

      return highlighted ? 'blue' : 'white'
    },

    experienceTimeTranslation (experienceTime) {
      const camelizedExperienceTime = toCamelCase(experienceTime)

      return this.$t(
        `${this.langPath}.experienceTime.${camelizedExperienceTime}`
      )
    },

    setShowModal (certificateId) {
      this.modalShownCertificateId = certificateId
    },

    closeModal () {
      this.modalShownCertificateId = undefined
    }
  }
}
</script>

<style lang="scss" scoped>
.candidate-profile-about {
  @extend %candidate-profile-top-border;

  .ev-badge {
    border-radius: $base;
    font: var(--tx-body);
    font-size: 0.875rem;
    letter-spacing: -0.1px;
    line-height: 1.25rem;
    padding: $base $base*2;
    text-transform: none;
  }

  &__section {
    &:not(:first-child) {
      margin-top: $base*6;
    }
  }

  &__description {
    font: var(--tx-body);
    margin-top: $base*2;
  }

  &__title {
    @extend %subtitle1;
  }

  &__main-skills {
    margin-top: $base*2;
  }

  &__main-skill {
    align-items: center;
    display: flex;
    margin-top: $base*2;
  }

  &__main-skill-period {
    @extend %body-text2;

    margin-left: $base*3;
  }

  &__skills {
    display: flex;
    flex-flow: row wrap;
    gap: $base*2;
    margin-top: $base*2;
  }

  &__certificates {
    margin-top: $base*8;

    &-modal-header {
      font: var(--tx-title-3);
      padding: $base;
    }

    &-modal-content {
      margin-top: $base*5;
      padding-left: $base;
    }

    &-modal-description {
      font: var(--tx-body-small);
    }

    &-modal-additional {
      margin-top: $base*4;
    }

    &-modal-link {
      margin-top: $base*5;
    }

    &-modal-title {
      font: var(--tx-subtitle-1);
    }
  }

  &__certificates-list {
    margin-top: $base*2;
  }

  &__certificates-link {
    font: var(tx--button);
  }
}
</style>
