<template>
  <section
    v-if="hasPdf"
    class="candidate-profile-cv"
    data-test="cv-section"
  >
    <iframe
      :src="candidate.cv"
      class="candidate-profile-cv__file"
      data-test="cv-iframe"
      frameborder="1"
    />
  </section>
</template>

<script>
export default {
  name: 'CandidateProfileCv',

  props: {
    candidate: {
      type: Object,
      required: true
    }
  },

  computed: {
    hasPdf () {
      return this.candidate.cvContentType === 'application/pdf'
    }
  }
}
</script>

<style lang="scss" scoped>
.candidate-profile-cv {
  @extend %candidate-profile-top-border;

  &__file {
    display: block;
    height: 125*$base;
    width: 100%;
  }
}
</style>
