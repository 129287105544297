<template>
  <section class="candidate-profile-incomplete">
    <div class="candidate-profile-incomplete__flex">
      <div class="candidate-profile-incomplete--image-group">
        <figure class="candidate-profile-incomplete__photo">
          <img
            :src="candidate.avatar"
            :alt="$t(`${langPath}.avatarAlt`)"
            class="candidate-profile-incomplete__photo__image"
            data-test="avatar"
          >
        </figure>

        <candidate-profile-favorite-button
          :candidate="candidate"
          class="candidate-profile-incomplete__favorite-button"
        />
      </div>

      <div class="candidate-profile-incomplete__header">
        <h2
          v-if="candidate.name"
          class="candidate-profile-incomplete__name"
          data-test="title"
        >
          {{ candidate.name }}

          <ev-badge
            class="candidate-profile-incomplete__name-ribbon"
            variant="gray"
            :title="$t(`${langPath}.ribbon`)"
          />
        </h2>

        <div class="candidate-profile-incomplete__placeholder-wrapper">
          <span class="candidate-profile-incomplete__placeholder-wrapper--fill-color">
            &nbsp;
          </span>
        </div>

        <div class="candidate-profile-incomplete__row-flex">
          <div class="candidate-profile-incomplete__placeholder-wrapper">
            <span class="candidate-profile-incomplete__placeholder-wrapper--fill-color">
              &nbsp;
            </span>
          </div>

          <div class="candidate-profile-incomplete__placeholder-wrapper">
            <span class="candidate-profile-incomplete__placeholder-wrapper--fill-color">
              &nbsp;
            </span>
          </div>

          <div class="candidate-profile-incomplete__placeholder-wrapper">
            <span class="candidate-profile-incomplete__placeholder-wrapper--fill-color">
              &nbsp;
            </span>
          </div>

          <div class="candidate-profile-incomplete__placeholder-wrapper">
            <span class="candidate-profile-incomplete__placeholder-wrapper--fill-color">
              &nbsp;
            </span>
          </div>
        </div>

        <div class="candidate-profile-incomplete__placeholder-wrapper">
          <span class="candidate-profile-incomplete__placeholder-wrapper--full-width">
            &nbsp;
          </span>
        </div>
      </div>
    </div>

    <div class="candidate-profile-incomplete__banner">
      <strong>
        <p class="candidate-profile-incomplete__banner--title">
          {{ $t(`${langPath}.title`) }}
        </p>
      </strong>
      <p class="candidate-profile-incomplete__banner--text">
        {{ $t(`${langPath}.text`) }}
      </p>
    </div>
  </section>
</template>

<script>
import CandidateProfileFavoriteButton from './CandidateProfileFavoriteButton'
import EvBadge from '@revelotech/everestV2/EvBadge'

export default {
  name: 'CandidatesProfileIncomplete',

  components: {
    CandidateProfileFavoriteButton,
    EvBadge
  },

  props: {
    candidate: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      langPath: __langpath
    }
  }
}
</script>

<style lang="scss">
.candidate-profile-incomplete {
  @extend %candidate-profile-section;

  margin-top: $base*6;

  @include breakpoint(md) {
    margin-top: 0;
  }

  &__photo {
    align-self: center;
    flex-shrink: 0;
    height: $base*25;
    width: $base*25;

    @include breakpoint(md) {
      align-self: flex-start;
    }

    &__image {
      height: 100%;
      max-width: 100%;
    }
  }

  &__name {
    @extend %h6;

    align-items: center;
    display: flex;
    margin-top: $base*5;
    text-align: center;

    @include breakpoint(md) {
      margin-top: 0;
      text-align: left;
    }

    &-ribbon {
      margin-left: $base*2;
    }
  }

  &__banner {
    @include margin(top, 4);

    background-color: var(--bg-neutral-light-04);
    border: 1px solid var(--bg-neutral-light-04);
    padding: 3*$base;

    &--title {
      @extend %subtitle1;

      display: inline-block;
    }

    &--text {
      @include margin(top, 3);

      @extend %body-text2;
    }
  }

  &__flex {
    display: flex;
    flex-direction: column;

    @include breakpoint(md) {
      flex-direction: row;
    }
  }

  &__header {
    @include breakpoint(md) {
      @include margin(left, 6);
    }
  }

  &__row-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__image-group {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  &__placeholder-wrapper {
    @include margin(left, 2);
    @include margin(top, 3);

    &--fill-color {
      background: var(--bg-neutral-light-04);
      display: inline-block;
      width: 30*$base;
    }

    &--full-width {
      background: var(--bg-neutral-light-04);
      display: inline-block;
      width: 100%;
    }
  }

  &__favorite-button {
    margin-top: $base*2;
  }
}
</style>
