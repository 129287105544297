export default ({ text, highlights, htmlClass = '', htmlTag = 'mark' }) => {
  if (typeof text !== 'string') return text
  if (highlights.length === 0) return text

  const sanitizedTargets = highlights.map(el =>
    `\\b${(el || '').replace(/[.*+?^${}()|[\]\\#]/g, '\\$&')}\\b`)

  const pattern = new RegExp(sanitizedTargets.join('|'), 'ig')

  const buildHighlight = (targetElement) =>
    `<${htmlTag} class="${htmlClass}">${targetElement}</${htmlTag}>`

  return text.replace(pattern, buildHighlight)
}
