<template>
  <div v-if="value">
    <app-modal
      class="external-evaluations__modal"
      data-test="modal"
      @close="closeModal"
    >
      <template slot="header">
        <h2
          class="external-evaluations__modal__title"
          data-test="external-evaluation-modal-title"
        >
          {{ $t(`${langPath}.modalTitle`) }}
        </h2>
      </template>

      <div class="external-evaluations__modal-body">
        <ul class="external-evaluations__list">
          <li
            v-for="evaluation in evaluations"
            :key="evaluation.id"
            class="external-evaluations__item"
            data-test="evaluation"
          >
            <header class="external-evaluations__modal-item-author">
              {{ evaluation.author }}
              ({{ evaluationCreatedAt(evaluation) }})
            </header>
            <p class="external-evaluations__modal-item-comments">
              {{ evaluation.comments }}
            </p>

            <ul class="external-evaluations__subjects">
              <li
                class="external-evaluations__subjects-item"
                v-for="subject in evaluation.subjects"
                :key="subject.id"
              >
                <span
                  class="external-evaluations__subject-label"
                  data-test="external-evaluation-subject"
                >
                  {{ subjectName(subject.name) }}
                </span>

                <app-thumbs
                  :name="parseInt(subject.id)"
                  :value="subject.score"
                  :read-only="true"
                />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </app-modal>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { CANDIDATES } from '@/store/namespaces'
import { toCamelCase } from '@/helpers/case-style'

const candidatesHelper = createNamespacedHelpers(CANDIDATES)

export default {
  name: 'CandidateProfileEvaluationsModal',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    ...candidatesHelper.mapState(['evaluations'])
  },
  methods: {
    closeModal () {
      this.$emit('input', false)
    },
    evaluationCreatedAt (evaluation) {
      return this.$d(new Date(evaluation.createdAt), 'dayMonthYear')
    },
    subjectName (name) {
      if (this.$te(`${this.langPath}.${toCamelCase(name)}`)) {
        return this.$t(`${this.langPath}.${toCamelCase(name)}`)
      }

      return name
    }
  }
}
</script>

<style scoped lang="scss">
.external-evaluations {
  height: fit-content;

  &__subjects {
    border-top: 1px solid var(--bg-neutral-light-02);
    margin: $base * 4 0 $base * 10 0;
    padding: $base * 4 0;

    &:not(:last-child) {
      border-bottom: 1px solid var(--bg-neutral-light-02);
    }

    &-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: $base * 2;
    }
  }

  &__modal {
    height: fit-content;

    &__title {
      margin: $base * 4 0;
    }

    &-item {
      &-author {
        margin: $base * 2 0 0 0;
      }

      &-comments {
        margin: $base * 2 0;
      }
    }
  }
}
</style>
