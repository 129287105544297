<template>
  <section class="candidate-profile-header-early-access-banner">
    <header class="candidate-profile-header-early-access-banner__header">
      <h5
        class="candidate-profile-header-early-access-banner__title"
        data-test="title"
      >
        {{ $t(`${langPath}.title`) }}
      </h5>

      <span
        class="candidate-profile-header-early-access-banner__ribbon"
        data-test="ribbon"
      >
        {{ $t(`${langPath}.ribbon`) }}
      </span>
    </header>

    <div class="candidate-profile-header-early-access-banner__text">
      <p data-test="text">
        {{ $t(`${langPath}.text`) }}
      </p>

      <strong>
        <p data-test="text-emphasis">
          {{ $t(`${langPath}.textEmphasis`) }}
        </p>
      </strong>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CandidateProfileHeaderEarlyAccessBanner',

  data () {
    return {
      langPath: __langpath
    }
  }
}
</script>

<style lang="scss">
.candidate-profile-header-early-access-banner {
  @include margin(top, 4);

  background-color: var(--bg-neutral-light-04);
  border: 1px solid var(--bg-neutral-light-04);
  padding: 3*$base;

  &__title {
    @extend %subtitle1;

    display: inline-block;
  }

  &__ribbon {
    @extend %ribbon-early-access;
  }

  &__text {
    @include margin(top, 3);

    @extend %body-text2;
  }
}
</style>
