<template>
  <section class="candidate-profile-header">
    <div class="candidate-profile-header__flex">
      <div class="candidate-profile-header__image-group">
        <figure class="candidate-profile-header__photo">
          <img :src="candidate.avatar" class="candidate-profile-header__photo-image">
        </figure>

        <section
          v-if="!hideWishLists"
          class="candidate-profile-header__actions-container"
          data-test="wish-lists"
        >
          <candidate-profile-favorite-button
            class="candidate-profile-header--spacing candidate-profile-header--hide-mobile"
            :candidate="candidate"
          />
        </section>
      </div>

      <div class="candidate-profile-header__body">
        <div class="candidate-profile-header__body-flex">
          <div
            class="candidate-profile-header__wrapper
                   candidate-profile-header__candidate-header
                   candidate-profile-header__wrapper--no-margin"
          >
            <h2 v-if="candidate.name" class="candidate-profile-header__candidate-name">
              {{ candidate.name }}
            </h2>

            <h3 class="candidate-profile-header__candidate-career">
              {{ candidate.focusesDescription }}
            </h3>

            <ev-badge
              v-if="candidate.newThisWeek"
              :title="$t(`${langPath}.newThisWeek`)"
              class="candidate-profile-header__ribbon"
              data-test="new-this-week"
              variant="green"
            />

            <a
              class="candidate-profile-header__evaluations"
              v-if="evaluations && evaluations.length"
              @click="openExternalEvaluationsModal"
              data-test="open-evaluations-modal"
            >
              <span
                class="candidate-profile-header__evaluations-info"
              >
                {{ evaluationCount }}
              </span>
              <ev-icon
                class="candidate-profile-header__evaluations-icon"
                name="feedback"
                size="mini"
                color="blue"
              />
            </a>

            <candidate-profile-evaluations-modal
              v-model="showEvaluations"
              data-test="evaluations-modal"
            />
          </div>

          <ev-button
            v-if="!hideShareButton"
            variant="secondary"
            size="medium"
            class="candidate-profile-header--hide-mobile"
            color="blue"
            data-test="share-button"
            @click="openShareProfileModal"
          >
            {{ $t(`${langPath}.shareProfile`) }}
          </ev-button>
        </div>

        <div class="candidate-profile-header__body-flex">
          <div
            v-if="candidate.livesIn"
            class="candidate-profile-header__wrapper candidate-profile-header__wrapper--small"
            data-test="lives-in"
          >
            <h4
              class="candidate-profile-header__title"
              data-test="candidate-profile-header-title"
            >
              {{ $t(`${langPath}.livesIn`) }}
            </h4>

            <p class="candidate-profile-header__info">{{ candidate.livesIn }}</p>
          </div>

          <div
            v-if="candidate.experienceTime"
            class="candidate-profile-header__wrapper candidate-profile-header__wrapper--small"
            data-test="experience-time"
          >
            <h4
              class="candidate-profile-header__title"
              data-test="candidate-profile-header-years-experience"
            >
              {{ $t(`${langPath}.yearsOfExperience`) }}
            </h4>

            <p
              class="candidate-profile-header__info"
              v-if="candidate.experienceTime"
            >
              {{ $t(`${langPath}.experienceTime.${formattedExperienceTime}`) }}
            </p>
          </div>

          <div
            v-if="showMinSalary"
            class="candidate-profile-header__wrapper candidate-profile-header__wrapper--small"
            data-test="minimum-salary"
          >
            <h4
              class="candidate-profile-header__title"
              data-test="profile-salary-expectation"
            >
              {{ $t(`${langPath}.salaryExpectation`) }}
            </h4>

            <p class="candidate-profile-header__info">
              {{ formattedSalary }}
            </p>
          </div>

          <div
            v-if="candidate.contractType"
            class="candidate-profile-header__wrapper candidate-profile-header__wrapper--small"
            data-test="contract-type"
          >
            <h4
              class="candidate-profile-header__title"
              data-test="candidate-profile-header-hiring-type"
            >
              {{ $t(`${langPath}.hiringType`) }}
            </h4>

            <p class="candidate-profile-header__info">{{ candidate.contractType }}</p>
          </div>
        </div>

        <div
          v-if="hasLocations"
          class="candidate-profile-header__wrapper"
          data-test="locations"
        >
          <h4
            class="candidate-profile-header__title"
            data-test="candidate-profile-header-locations"
          >
            {{ $t(`${langPath}.locations`) }}
          </h4>

          <p class="candidate-profile-header__info">{{ locations }}</p>
        </div>

        <candidate-profile-header-languages :candidate="candidate" />
      </div>
    </div>

    <candidate-profile-header-early-access-banner v-if="candidate.earlyAccess" />
  </section>
</template>

<script>
import CandidateProfileFavoriteButton from './CandidateProfileFavoriteButton'
import CandidateProfileHeaderLanguages from
  './CandidateProfileHeader/CandidateProfileHeaderLanguages'
import CandidateProfileHeaderEarlyAccessBanner from
  './CandidateProfileHeader/CandidateProfileHeaderEarlyAccessBanner'
import EvBadge from '@revelotech/everestV2/EvBadge'
import EvButton from '@revelotech/everestV2/EvButton'
import EvIcon from '@revelotech/everestV2/EvIcon'
import CandidateProfileEvaluationsModal from './CandidateProfileEvaluationsModal'

import eventBus from '@/support/event-bus'
import { toCamelCase } from '@/helpers/case-style'

import { createNamespacedHelpers } from 'vuex'
import { CURRENT_USER, CANDIDATES } from '@/store/namespaces'

const candidatesHelper = createNamespacedHelpers(CANDIDATES)
const currentUserHelper = createNamespacedHelpers(CURRENT_USER)

export default {
  name: 'CandidateProfileHeader',

  components: {
    CandidateProfileHeaderEarlyAccessBanner,
    CandidateProfileFavoriteButton,
    CandidateProfileHeaderLanguages,
    CandidateProfileEvaluationsModal,
    EvBadge,
    EvButton,
    EvIcon
  },

  props: {
    candidate: {
      type: Object,
      required: true
    },

    hideWishLists: {
      type: Boolean,
      default: false
    },

    hideShareButton: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      langPath: __langpath,
      showEvaluations: false
    }
  },
  computed: {
    ...currentUserHelper.mapState(['currentUser']),
    ...candidatesHelper.mapState(['evaluations']),

    evaluationCount () {
      const firstAuthor = this.evaluations[0]?.author
      const authorsLength = this.evaluations.length

      return this.$tc(
          `${this.langPath}.evaluationCount`,
          authorsLength - 1,
          { firstAuthor, otherAuthors: authorsLength - 1 }
      )
    },
    showMinSalary () {
      return Boolean(
        this.candidate.minimumSalary && this.currentUser?.canSeeSalary
      )
    },

    locations () {
      return this.candidate.locations.map(location => location.name).join(', ')
    },

    hasLocations () {
      return this.candidate.locations?.length > 0
    },

    formattedExperienceTime () {
      return toCamelCase(this.candidate.experienceTime)
    },

    formattedSalary () {
      const salary = Number(this.candidate.minimumSalary).toLocaleString().replace(',', '.')

      return this.$t(`${this.langPath}.salary`, { salary })
    }
  },

  methods: {
    openExternalEvaluationsModal () {
      this.showEvaluations = true
    },
    openShareProfileModal () {
      eventBus.emit('candidates/toggleShareProfileModal', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.candidate-profile-header {
  margin-top: $base*6;
  position: relative;

  @include breakpoint(md) {
    @include margin(top, 0);
  }

  &__image-group {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  &__flex {
    display: flex;
    flex-direction: column;

    @include breakpoint(md) {
      flex-direction: row;
    }
  }

  &__candidate-header {
    align-items: center;
    display: flex;
    flex-direction: column;

    @include breakpoint(md) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &--spacing {
    @include margin(top, 2);
  }

  &--hide-mobile {
    display: none;

    @include breakpoint(md) {
      display: block;
    }
  }

  &__photo {
    align-self: center;
    flex-shrink: 0;
    padding: $base;

    @include breakpoint(md) {
      align-self: flex-start;
    }
  }

  &__photo-image {
    background-color: var(--bg-neutral-light-04);
    border: solid $base var(--b-neutral-blank);
    border-radius: 50%;
    box-shadow: $shadow-8;
    height: $base*28;
    width: $base*28;

    @include breakpoint(md) {
      border: none;
      border-radius: inherit;
      box-shadow: none;
    }
  }

  &__body {
    width: 100%;

    @include breakpoint(md) {
      @include margin(left, 6);
    }
  }

  &__body-flex {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__evaluations {
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: $base;
    text-decoration: underline;

    &-info {
      @extend %body-text2;

      color: var(--tx-neutral-light-01);
    }
  }

  &__wrapper {
    @include margin(top, 5);

    flex: 0 100%;

    @include breakpoint(md) {
      flex: 1 50%;
    }

    &--no-margin {
      margin-top: 0;
    }

    &--small {
      flex: 0 50%;

      @include breakpoint(md) {
        flex: 0 calc(100% / 3);
      }
    }
  }

  &__title {
    @extend %caption;

    color: var(--tx-neutral-light-01);
  }

  &__info {
    @extend %body-text2;

    &-text {
      text-align: left;
    }
  }

  &__ribbon {
    @include margin(left, 2);
    @include margin(top, 2);

    flex-shrink: 0;
    padding-bottom: $base;
    padding-top: $base;

    @include breakpoint(md) {
      @include margin(top, 0);

      order: -1;
    }
  }

  &__candidate-name {
    @include margin(top, 5);

    @extend %h6;

    color: var(--tx-neutral-base);
    order: -10;
    text-align: center;

    @media (max-width: (135*$base)) {
      flex: 0 1 100%;
      justify-content: center;
    }

    @include breakpoint(md) {
      @include margin(top, 0);

      text-align: left;
    }
  }

  &__candidate-career {
    @extend %body-text2;

    text-align: center;

    @include breakpoint(md) {
      flex: 1 1 100%;
      text-align: left;
    }
  }
}
</style>
