<template>
  <section>
    <app-dropdown-menu
      class="candidate-profile-favorite-button"
      data-test="dropdown-menu"
    >
      <template slot="title">
        <figure class="candidate-profile-favorite-button__icon">
          <icn-filled-bookmark
            v-if="isFavorite"
            data-test="is-favorite-bookmark"
            name="favorite-filled-bookmark"
          />
          <icn-bookmark
            v-else
            class="candidate-profile-favorite-button__icon--favorite-bookmark"
            name="favorite-bookmark"
          />
        </figure>
        <span
          class="candidate-profile-favorite-button__title"
          data-test="candidates-favorite"
        >
          {{ $t(`${langPath}.favorite`) }}
        </span>
      </template>

      <app-item-menu
        v-for="list in decoratedWishList"
        :key="list.id"
        :class="[list.style]"
        :data-test="`toggle-favorite-${list.id}`"
        @click="toggleFavorite(list)"
      >
        {{ list.name }}
      </app-item-menu>

      <app-item-menu
        v-if="showForm"
        ref="newForm"
        class="candidate-profile-favorite-button__new"
      >
        <div class="candidate-profile-favorite-button__flex">
          <app-text-input
            v-model="newWishList"
            data-test="wish-list-input"
            name="newFavorite"
          />
          <ev-button
            :disabled="isCreatingWishList"
            class="candidate-profile-favorite-button__submit"
            data-test="create-wishlist"
            size="medium"
            @click="createWishListClick"
          >
            {{ $t(`${langPath}.newListBtn`) }}
          </ev-button>
        </div>
      </app-item-menu>

      <app-item-menu
        v-else
        ref="newButton"
        class="candidate-profile-favorite-button__new"
        data-test="enable-create-new-wish-list"
        @click="openForm"
      >
        <icn-add-red class="candidate-profile-favorite-button__icon-red" />
        <span
          class="candidate-profile-favorite-button__action"
          data-test="candidates-favorite-new-list"
        >
          {{ $t(`${langPath}.newList`) }}
        </span>
      </app-item-menu>
    </app-dropdown-menu>
  </section>
</template>

<script>
import { IcnAddRed, IcnBookmark, IcnFilledBookmark } from '@revelotech/everest'
import { createNamespacedHelpers } from 'vuex'
import { WISH_LISTS, NOTIFICATION_CARDS } from '@/store/namespaces'
import EvButton from '@revelotech/everestV2/EvButton'

const wishListHelper = createNamespacedHelpers(WISH_LISTS)
const notificationCardsHelper = createNamespacedHelpers(NOTIFICATION_CARDS)

export default {
  name: 'CandidatesFavoriteButton',

  components: {
    EvButton,
    IcnBookmark,
    IcnFilledBookmark,
    IcnAddRed
  },

  props: {
    candidate: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      langPath: __langpath,
      showForm: false,
      newWishList: ''
    }
  },

  computed: {
    ...wishListHelper.mapState(['isCreatingWishList']),
    ...wishListHelper.mapGetters(['wishListsFavoritedByCandidateId']),

    wishLists () {
      return this.wishListsFavoritedByCandidateId(this.candidate.id)
    },

    isFavorite () {
      return this.wishLists.some(({ favorite }) => favorite)
    },

    decoratedWishList () {
      return this.wishLists.map((list) => ({
        ...list,
        style: list.favorite ? 'candidate-profile-favorite-button--favorite' : ''
      }))
    }
  },

  created () {
    this.getWishLists()
  },

  methods: {
    ...notificationCardsHelper.mapActions(['createNotificationCard']),
    ...wishListHelper.mapActions([
      'getWishLists',
      'addCandidate',
      'removeCandidate',
      'createWishList'
    ]),

    toggleFavorite (wishList) {
      const obj = { wishListId: wishList.id, candidateId: this.candidate.id }
      if (wishList.favorite) {
        this.removeCandidate(obj)
      } else {
        this.addCandidate(obj)
      }
    },

    openForm () {
      this.newWishList = ''
      this.showForm = true
    },

    closeForm () {
      this.showForm = false
    },

    hasDuplicatedNameError (error) {
      const errors = error.response.data.errors
      const hasDuplicatedNameError = errors?.some(({ errorType }) =>
        errorType === 'wish_list.error.duplicated_name')

      const isBadRequest = error.response.status === 422

      return isBadRequest && hasDuplicatedNameError
    },

    async createWishListClick () {
      try {
        const wishList = await this.createWishList(this.newWishList)

        this.addCandidate({
          wishListId: wishList.id,
          candidateId: this.candidate.id
        })

        this.closeForm()
      } catch (error) {
        if (this.hasDuplicatedNameError(error)) {
          this.createNotificationCard({
            title: this.$t(`${this.langPath}.duplicatedNameError.title`),
            message: this.$t(`${this.langPath}.duplicatedNameError.message`, {
              name: this.newWishList
            }),
            modifier: 'error'
          })
        } else {
          this.createNotificationCard({
            title: this.$t(`${this.langPath}.genericError.title`),
            message: this.$t(`${this.langPath}.genericError.message`),
            modifier: 'error'
          })
          this.$rollbar.error(error)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.candidate-profile-favorite-button {
  border: 1px solid var(--b-neutral-light-03);
  z-index: 2;

  &__title {
    margin-right: $base * 2;
  }

  &__icon {
    margin-left: $base * 2;
    min-width: 6 * $base;

    &--favorite-bookmark {
      left: -1.25 * $base;
      position: relative;
    }
  }

  &__icon-red {
    vertical-align: sub;
  }

  &__action {
    @include margin(left, 2);
  }

  &__new {
    width: 250px;

    &:not(:first-child) {
      border-top: 1px solid var(--b-neutral-light-04);
    }
  }

  &__flex {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
  }

  &__submit {
    margin-top: $base * 2;
  }

  &--favorite {
    @extend %bold;

    background: var(--bg-neutral-light-04);
  }
}
</style>
