<template>
  <section v-if="hasEducations" class="candidate-profile-educations">
    <h3
      class="candidate-profile-educations__title"
      data-test="profile-education-title"
    >
      {{ $t(`${langPath}.title`) }}
    </h3>

    <div
      v-for="education in orderedEducation"
      :key="education.id"
      class="candidate-profile-educations__item"
      data-test="education"
    >
      <div class="candidate-profile-educations__item-circle" />

      <h3 class="candidate-profile-educations__item-university">
        {{ education.uni }}
      </h3>
      <h4 class="candidate-profile-educations__item-course">
        {{ education.course }}, {{ education.degree }}
      </h4>
      <time class="candidate-profile-educations__item-period">
        {{ education.gradYear }}
      </time>
    </div>
  </section>
</template>

<script>
import orderBy from 'lodash/orderBy'

export default {
  name: 'CandidateProfileEducations',

  props: {
    candidate: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      langPath: __langpath
    }
  },

  computed: {
    orderedEducation () {
      return orderBy(this.candidate.educations, 'gradYear', 'desc')
    },
    hasEducations () {
      return this.candidate.educations && this.candidate.educations.length > 0
    }
  }
}
</script>

<style lang="scss" scoped>
.candidate-profile-educations {
  @extend %candidate-profile-top-border;

  &__title {
    @extend %subtitle1;
  }

  &__item {
    color: var(--tx-neutral-light-01);
    padding-left: 7*$base;
    position: relative;

    @include breakpoint(md) {
      padding-left: 7*$base;

      &::before {
        border-left: 1px solid var(--b-neutral-light-02);
        bottom: -1*$base;
        content: '';
        left: $base;
        position: absolute;
        top: $base;
      }
    }

    &:first-of-type {
      @include margin(top, 6);
    }

    &:not(:last-child) {
      padding-bottom: 9*$base;
    }

    &-university {
      @extend %subtitle1;
    }

    &-course {
      @extend %body-text2;
    }

    &-period {
      @extend %caption;

      color: var(--tx-neutral-light-01);
    }

    &-description {
      @include margin(top, 2);

      @extend %body-text1;
    }

    &-circle {
      background: var(--bg-neutral-light-02);
      border-radius: 50%;
      display: none;
      height: 3*$base;
      left: 0;
      position: absolute;
      top: $base;
      width: 3*$base;

      &--current {
        background: var(--bg-neutral-light-01);
      }

      @include breakpoint(md) {
        display: block;
      }
    }
  }
}
</style>
