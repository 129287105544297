<template>
  <section
    v-if="hasSocial"
    class="candidate-profile-social"
    data-test="profile-social"
  >
    <a
      v-if="candidate.sanitizedCv"
      class="candidate-profile-social__link"
      :href="candidate.sanitizedCv "
      data-test="cv-link"
      target="_blank"
      @click="sendMoreInfoEvent('Curriculum')"
    >
      <ev-icon
        name="file-info-alt"
        class="candidate-profile-social__image"
        :alt="$t(`${langPath}.cv`)"
      />
      {{ $t(`${langPath}.cv`) }}
    </a>
    <a
      v-if="candidate.sanitizedLinkedin"
      class="candidate-profile-social__link"
      :href="candidate.sanitizedLinkedin"
      target="_blank"
      data-test="linkedin-link"
      @click="sendMoreInfoEvent('LinkedIn')"
    >
      <ev-icon
        name="linkedin"
        class="candidate-profile-social__image"
        :alt="$t(`${langPath}.linkedin`)"
      />
      {{ $t(`${langPath}.linkedin`) }}
    </a>
    <a
      v-if="candidate.sanitizedGithub"
      class="candidate-profile-social__link"
      :href="candidate.sanitizedGithub"
      target="_blank"
      data-test="github-link"
    >
      <ev-icon
        name="github"
        class="candidate-profile-social__image"
        :alt="$t(`${langPath}.github`)"
      />
      {{ $t(`${langPath}.github`) }}
    </a>
    <a
      v-if="candidate.sanitizedBlog"
      class="candidate-profile-social__link"
      :href="candidate.sanitizedBlog"
      target="_blank"
      data-test="blog-link"
    >
      <ev-icon
        name="window"
        class="candidate-profile-social__image"
        :alt="$t(`${langPath}.blog`)"
      />
      {{ $t(`${langPath}.blog`) }}
    </a>
    <a
      v-if="candidate.sanitizedPortifolio"
      class="candidate-profile-social__link"
      :href="candidate.sanitizedPortifolio"
      target="_blank"
      data-test="portfolio-link"
    >
      <ev-icon
        name="window"
        class="candidate-profile-social__image"
        :alt="$t(`${langPath}.behance`)"
      />
      {{ $t(`${langPath}.portifolio`) }}
    </a>
    <a
      v-if="candidate.sanitizedStackoverflow"
      class="candidate-profile-social__link"
      :href="candidate.sanitizedStackoverflow"
      target="_blank"
      data-test="stackoverflow-link"
    >
      <icn-stackoverflow
        :alt="$t(`${langPath}.stackoverflow`)"
        class="candidate-profile-social__image"
      />
      {{ $t(`${langPath}.stackoverflow`) }}
    </a>
    <a
      v-if="candidate.sanitizedBehance"
      class="candidate-profile-social__link"
      :href="candidate.sanitizedBehance"
      target="_blank"
      data-test="behance-link"
    >
      <ev-icon
        name="behance"
        class="candidate-profile-social__image"
        :alt="$t(`${langPath}.behance`)"
      />
      {{ $t(`${langPath}.behance`) }}
    </a>
    <a
      v-if="candidate.sanitizedDribbble"
      class="candidate-profile-social__link"
      :href="candidate.sanitizedDribbble"
      target="_blank"
      data-test="dribbble-link"
    >
      <ev-icon
        name="dribbble"
        class="candidate-profile-social__image"
        :alt="$t(`${langPath}.dribbble`)"
      />
      {{ $t(`${langPath}.dribbble`) }}
    </a>
  </section>
</template>

<script>
import events from '@/repository/companies-app/events'
import EvIcon from '@revelotech/everestV2/EvIcon'
import { IcnStackoverflow } from '@revelotech/everest'

export default {
  name: 'CandidateProfileSocial',
  components: {
    IcnStackoverflow,
    EvIcon
  },
  props: {
    candidate: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    hasSocial () {
      return (
        this.candidate.blog ||
        this.candidate.portifolio ||
        this.candidate.dribbble ||
        this.candidate.behance ||
        this.candidate.github ||
        this.candidate.stackoverflow ||
        this.candidate.cv ||
        this.candidate.linkedin
      )
    }
  },
  methods: {
    sendMoreInfoEvent (resource) {
      events.createEvent({
        event: 'MoreInfo',
        data: {
          candidate_id: this.candidate.id,
          resource
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.candidate-profile-social {
  @extend %candidate-profile-top-border;

  display: flex;
  flex-wrap: wrap;

  @include breakpoint(md) {
    flex-wrap: no-wrap;
  }

  &__link {
    @extend %caption;

    align-items: center;
    display: flex;
    flex-direction: column;

    &:not(:first-of-type) {
      @include margin(left, 5);
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__image {
    height: 6*$base;
    width: 6*$base;
  }

  &__info {
    @extend %body-text2;

    &-text {
      text-align: left;
    }

    &--inline {
      align-items: center;
      display: flex;

      svg {
        height: 5*$base;
        padding-right: $base;
        width: 5*$base;

        path {
          fill: var(--bg-blue);
        }
      }
    }

    &--clickable {
      &:active {
        transform: scale(0.98);
      }
    }

    &-hidden-links {
      @extend %bold;

      color: var(--tx-blue);
    }
  }
}
</style>
