<template>
  <div class="candidate-profile-job-experience">
    <div
      class="candidate-profile-job-experience__circle"
      :class="currentClass"
      data-test="current-skill"
    />

    <ev-circle-loader
      v-if="isFetchingSkills"
      class="candidate-profile-job-experience__loading"
      data-test="loader"
      variant="secondary"
    />

    <template v-else>
      <h3 class="candidate-profile-job-experience__company">{{ job.company }}</h3>

      <h4
        class="candidate-profile-job-experience__position"
        :inner-html.prop="highlightedJobTitle"
        data-test="job-title"
      />

      <div class="candidate-profile-job-experience__period">
        <time
          :datetime="job.startDate"
          class="candidate-profile-job-experience__start-date"
          data-test="start-date"
        >
          {{ startDateText }}
        </time>
        -
        <time
          :datetime="job.endDate"
          class="candidate-profile-job-experience__end-date"
          data-test="end-date"
        >
          {{ endDateText }}
        </time>

        <time
          :datetime="accessibleDuration"
          class="candidate-profile-job-experience__duration"
          data-test="duration"
        >
          ({{ humanizedDuration }})
        </time>
      </div>

      <app-view-more>
        <p
          class="candidate-profile-job-experience__description"
          :inner-html.prop="hightlightedJobDescription"
          data-test="job-description"
        />
      </app-view-more>

      <section
        v-if="showSkills"
        class="candidate-profile-job-experience__skills-section"
        data-test="skills-section"
      >
        <div
          v-for="skill in job.skills"
          class="candidate-profile-job-experience__skill"
          :key="skill.id"
        >
          <span
            class="candidate-profile-job-experience__skill-content"
            :class="`candidate-profile-job-experience__skill-content--${skillTagVariant(skill.id)}`"
            data-test="skill"
          >
            {{ skill.name }}
          </span>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
import EvCircleLoader from '@revelotech/everestV2/EvCircleLoader'

import { createNamespacedHelpers } from 'vuex'
import { SKILLS } from '@/store/namespaces'

import { toCapitalize } from '@/helpers/case-style'
import dateMethods from '@/helpers/date-methods'
import highlightTexts from '@/helpers/text-highlight'

const skillsHelper = createNamespacedHelpers(SKILLS)

export default {
  name: 'CandidateProfileJobExperience',

  components: { EvCircleLoader },

  props: {
    highlightedSkillIds: {
      type: Array,
      default: () => []
    },

    job: {
      type: Object,
      required: true
    }
  },

  data () {
    return { langPath: __langpath }
  },

  computed: {
    ...skillsHelper.mapState(['skills', 'isFetchingSkills']),

    endDateText () {
      if (this.job.endDate) {
        return toCapitalize(this.$d(new Date(this.job.endDate), 'monthYear'))
      }

      return this.$t(`${this.langPath}.current`)
    },
    startDateText () {
      return toCapitalize(this.$d(new Date(this.job.startDate), 'monthYear'))
    },
    highlightedJobTitle () {
      return highlightTexts({
        text: this.job.title,
        highlights: this.highlightedNames,
        htmlClass: 'candidate-profile-job-experience__text-highlight'
      })
    },
    hightlightedJobDescription () {
      return highlightTexts({
        text: this.job.description,
        highlights: this.highlightedNames,
        htmlClass: 'candidate-profile-job-experience__text-highlight'
      })
    },
    currentClass () {
      return this.job.current ? 'candidate-profile-job-experience__circle--current' : ''
    },
    accessibleDuration () {
      return dateMethods.machineDateDifference(
        this.job.startDate,
        this.job.endDate || new Date().toISOString()
      )
    },
    humanizedDuration () {
      return dateMethods.humanDateDifference(
        this.job.startDate,
        this.job.endDate || new Date().toISOString()
      )
    },
    showSkills () {
      return this.job.skills.length > 0
    },
    highlightedNames () {
      return this.skills
        .filter((skill) => this.skillIdHighlighted(skill.id))
        .map((skill) => skill.name)
    }
  },

  methods: {
    skillIdHighlighted (skillId) {
      return Boolean(
        this.highlightedSkillIds.find((id) => String(id) === String(skillId))
      )
    },

    skillTagVariant (skillId) {
      return this.skillIdHighlighted(skillId) ? 'blue' : 'white'
    }
  }
}
</script>

<style lang="scss" scoped>
.candidate-profile-job-experience {
  color: var(--tx-neutral-light-01);
  padding-left: 7*$base;
  position: relative;

  @include breakpoint(md) {
    padding-left: 7*$base;

    &::before {
      border-left: 1px solid var(--b-neutral-light-02);
      bottom: 0;
      content: "";
      left: 6px;
      position: absolute;
      top: 0;
    }
  }

  &:first-of-type {
    @include margin(top, 6);

    &::before {
      top: $base;
    }
  }

  &:not(:last-child) {
    padding-bottom: 9*$base;
  }

  &__loading {
    display: block;
  }

  &__company {
    @extend %subtitle2;
  }

  &__position {
    @extend %body-text2;
  }

  &__period {
    @extend %caption;

    color: var(--tx-neutral-light-01);
    display: block;
  }

  &__description {
    @include margin(top, 2);

    @extend %body-text2;

    word-break: break-word;
  }

  &__skills-section {
    display: flex;
    flex-wrap: wrap;
  }

  &__text-highlight {
    @extend %bold;

    background-color: initial;
    color: var(--tx-blue);
  }

  &__skill {
    @include margin(top, 4);

    &:not(:last-child) {
      @include margin(right, 2);
    }

    &-content {
      @extend %body-text2;

      border-radius: $app-border-radius;
      padding: $base 2*$base;

      &--white {
        border: 1px solid var(--b-neutral-light-02);
        color: var(--tx-neutral-base);
      }

      &--blue {
        background: var(--bg-blue);
        color: var(--tx-neutral-blank);
      }
    }
  }

  &__circle {
    background: var(--bg-neutral-light-02);
    border-radius: 50%;
    display: none;
    height: 13px;
    left: 0;
    position: absolute;
    top: 3px;
    width: 13px;

    &--current {
      background: var(--bg-neutral-light-01);
    }

    @include breakpoint(md) {
      display: block;
    }
  }
}
</style>
